// Plugins

// FontAwesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

// Header Styles
.read-only, .logged-in-as {
  margin: 0 !important;
  border-radius: 0 !important;
}
.slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.td-container {
  padding: 0!important;
}
.filter-container {
  border-top: 1px solid!important;
  position: fixed;
  bottom: 0!important;
  left:0;
  right:0;
  background-color: #F6F6F6;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
  margin: -2px 0 0 0;
  z-index:9999;
}
.grid-layout {

}
.reset-button {
  margin-bottom: 20px
}
/*.expand-container {
  width: 115px!important;
}*/


.shop-orders {
  background-color: #E3EFFC;
}

.v-btn {
  text-decoration: none !important;
}

.form-row.collapsed-form-row > .col,
.form-row.collapsed-form-row > [class*=col-] {
    padding-top:0;
    padding-bottom:0;
}
